<template>
    <template v-for="(memorandum, index) in memorandums" :key="index">
        <div class="overlay"></div>
    </template>
    <div class="content">
        <div :class="{ 'collapsed': collapsed }">
            <div class="navigation flex wrap end">
                <nav class="flex wrap vcenter">
                    <div class="right flex wrap start vcenter">
                        <button class="flex wrap center vcenter" type="button" @click.prevent="collapsed = !collapsed">
                            <i class="fe fe-16 fe-menu"></i>
                        </button>
                        <button class="flex wrap center vcenter" type="button"
                            @click.prevent="$router.push('/Dashboard')">
                            <i class="fe fe-16 fe-home"></i>
                        </button>
                        <!-- <div v-if="grade >= 4" class="wallet flex wrap center vcenter"
                            @click="$router.push('/Transaction/1/15')">
                            <i class="fe fe-16 fe-credit-card"></i>
                            <span>مانده دردسترس: </span>
                            <p>{{ credit }} ریال</p>
                        </div> -->
                    </div>
                    <div class="left flex wrap end vcenter">
                        <div class="profile flex wrap end vcenter">
                            <p>{{ time }}</p>
                            <span class="pulse"
                                :class="[{ 'circle-green': status === '1' }, { 'circle-red': status === '0' }]"></span>
                            <div class="flex wrap end vcenter" @click.prevent="dropdown = !dropdown">
                                <img src="/images/profile.jpg" height="32" width="32">
                                <span class="flex wrap center vcenter">{{ display_name }}</span>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <aside calss="flex wrap center">
                <i class="fe fe-x x" data-toggle="toggle" @click.prevent="collapsed = true"></i>
                <div class="logo flex wrap center">
                    <a class="flex wrap center" href="https://atiyacrowd.ir" target="_blank">
                        <img v-if="!collapsed" src="../assets/images/hlogo.png">
                        <img v-if="collapsed" class="brand" src="../assets/images/wicon.png" height="42" width="42">
                    </a>
                </div>
                <div class="navbar">
                    <template v-for="(category, cindex) in modules['categories']" :key="cindex">
                        <div class="categories">
                            <p class="category">{{ category.name }}</p>
                            <ul>
                                <template v-for="(menu, mindex) in modules['menus'].filter(menu => {
                                    return menu.category === category.id
                                })" :key="mindex">
                                    <li class="items flex wrap vcenter">
                                        <a class="flex wrap vcenter" :class="[{ 'active': active[menu.id] },
                                        { 'single': base.Util().validate(menu.route) }]"
                                            :href="menu.route ? menu.route : '#M' + menu.id" data-toggle="collapse"
                                            @click.prevent="menu.route ? route(menu.extras, menu.route) : collapsed = false">
                                            <i :class="menu.symbol"></i>
                                            <span class="flex wrap space vcenter">{{ menu.name }}</span>
                                        </a>
                                        <ul v-if="!collapsed" class="flex wrap collapse" :id="'M' + menu.id">
                                            <template v-for="(option, oindex) in modules['options'].filter(option => {
                                                return option.menu === menu.id
                                            })" :key="oindex">
                                                <li>
                                                    <a :class="{ 'active': active[option.id] }"
                                                        @click.prevent="route(option.extras, option.route)">
                                                        <p>{{ option.name }}</p>
                                                    </a>
                                                </li>
                                            </template>
                                        </ul>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </template>
                </div>
            </aside>
            <main class="flex wrap center">
                <div>
                    <RouterView />
                </div>
            </main>
            <!-- <div v-if="dropdown" class="dropdown flex wrap center vcenter">
                <div>
                    <p>نمایه کاربری</p>
                </div>
                <div>
                    <p>نشست های فعال</p>
                </div>
                <div>
                    <p>راهنمای پرتال</p>
                </div>
                <div @click.prevent="base.Security().logout()">
                    <p>خروج</p>
                </div>
            </div> -->
        </div>
    </div>
    <template v-for="(memorandum, index) in memorandums" :key="index">
        <Dialog :data="{
            title: memorandum.name,
            description: `اینجانب ${$cookies.get('display_name')} به کد یا شناسه ملی / کد فراگیر اتباع ${$cookies.get('username')} ضمن مطالعه بیانیه ریسک، تمامی مفاد و شرایط مندرج را می‌پذیرم.`,
            extras: { title: 'مطالعه بیانیه ریسک', entity: memorandum },
            button: { key: 'submit', name: 'می‌پذیرم', style: JSON.stringify({ 'width': '30%' }) }, type: 'text'
        }" @onclick="onclick" :class="{ 'show': true }"></Dialog>
    </template>
</template>

<script>
/* eslint-disable */
import { RouterView } from 'vue-router'
import Dialog from '../components/Dialog.vue'

require('../assets/js/bootstrap')

export default {
    name: 'Dashboard',
    props: ['authenticated'],
    inject: ['base'],
    components: { RouterView, Dialog },
    mounted() {
        document.getElementById('theme')?.remove()
        let theme = document.createElement('meta')
        theme.setAttribute('name', 'theme-color')
        theme.setAttribute('content', '#287088')
        document.head.appendChild(theme)
    },
    created() {
        this.base.API().get(`Service/Service/Time`, (response) => {
            if (this.base.Util().success(response)) {
                localStorage.setItem('time', response.data.message)
                localStorage.setItem('status', '1')
                this.ntp('parse', this.ntp('get'))
                this.ntp('stopwatch')
            }
        })

        if (window.screen.width < 430)
            this.collapsed = true
        this.init()
    },
    data() {
        return {
            grade: Number(this.$cookies.get('grade')),
            modules: [],
            collapsed: false,
            dropdown: false,
            credit: '0',
            display_name: '',
            status: '0',
            time: '',
            active: {},
            memorandums: []
        }
    },
    methods: {
        init() {
            this.display_name = this.$cookies.get('display_name')
            this.status = localStorage.getItem('status')
            setInterval(() => {
                this.status = localStorage.getItem('status')
                if (this.status === null || (this.status !== '0' && this.status !== '1'))
                    this.status = '0'
            }, 1000)

            let modules = eval(localStorage.getItem('modules'))
            if (this.base.Util().validate(modules))
                this.modules = modules[0]

            if (this.authenticated) {
                this.base.API().get(`Security/Access/Produce?uid=${this.$cookies.get('uid')}&gid=${this.$cookies.get('gid')}&rid=${this.$cookies.get('rid')}`
                    , (response) => {
                        if (this.base.Util().success(response)) {
                            if (this.base.Util().validate(response.data.accesses))
                                localStorage.setItem('accesses', JSON.stringify(response.data.accesses))

                            if (this.base.Util().validate(response.data.categories, response.data.menus)
                                && this.base.Util().validate(response.data.menus)) {
                                localStorage.setItem('modules', JSON.stringify([{
                                    categories: response.data.categories.sort((m1, m2) => (m1.order > m2.order) ? 1 : (m1.order < m2.order) ? -1 : 0),
                                    menus: response.data.menus.sort((m1, m2) => (m1.order > m2.order) ? 1 : (m1.order < m2.order) ? -1 : 0),
                                    options: response.data.options.sort((m1, m2) => (m1.order > m2.order) ? 1 : (m1.order < m2.order) ? -1 : 0),
                                }]))
                            } else {
                                localStorage.setItem('accesses', JSON.stringify([]))
                                localStorage.setItem('modules', JSON.stringify([]))
                            }
                        }
                    })
                this.base.API().post('Data/Meta/Find', { data: {} }, (response) => {
                    if (this.base.Util().success(response)) {
                        if (this.base.Util().validate(response.data.data))
                            localStorage.setItem('metas', JSON.stringify(response.data.data))
                        else
                            localStorage.setItem('metas', JSON.stringify([]))
                    }
                })
                this.base.API().post('Data/Entities/Find', { data: {} }, (response) => {
                    if (this.base.Util().success(response)) {
                        if (this.base.Util().validate(response.data.data))
                            localStorage.setItem('entities', JSON.stringify(response.data.data))
                        else
                            localStorage.setItem('entities', JSON.stringify([]))
                    }
                })
                if (Number(this.$cookies.get('grade')) >= 4) {
                    this.base.API().post('Finance/Credit/Find', { data: { uid: Number(this.$cookies.get('uid')) } }, (response) => {
                        if (this.base.Util().success(response)) {
                            if (this.base.Util().validate(response.data.data)) {
                                this.credit = String(response.data.data[0].balance - response.data.data[0].inprocess).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                this.$cookies.set('credit', this.credit.replace(/\s/g, '').replace(/,/g, ''))
                            }
                            else {
                                this.credit = '0'
                                this.$cookies.set('credit', '0')
                            }
                        }
                    })
                    this.base.API().post('Privacy/Memorandum/Find', { data: { status: 100 } }, (response) => {
                        if (this.base.Util().success(response)) {
                            if (this.base.Util().validate(response.data.data))
                                response.data.data.forEach(element => {
                                    this.base.API().post('Privacy/Signature/Find', { data: { mid: element.id, uid: Number(this.$cookies.get('uid')), status: 100 } }, (response) => {
                                        if (!this.base.Util().success(response) && response.data.errorCode === 4004)
                                            this.memorandums.push(element)
                                    })
                                });
                        }
                    })
                }
            }
        },
        ntp(action = 'get', extra) {
            if (action === 'get')
                return Number(localStorage.getItem('time'))
            else if (action === 'stopwatch') {
                let time = this.ntp('get')
                if (time) {
                    let date = new Date(time)
                    setInterval(() => {
                        time += 1000
                        this.ntp('parse', time)
                    }, 1000)
                }
            } else if (action === 'parse') {
                let date = new Date(extra)
                let hour = date.getHours()
                let minute = date.getMinutes()
                let second = date.getSeconds()
                this.time = `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}:${second < 10 ? '0' + second : second}`
            }
        },
        route(extras, route) {
            let extra = this.base.Entities().parse(extras)
            if (extra['params'])
                route = route.replace(`{${extra['params']}}`, this.$cookies.get(extra['params']))

            if (!extra['type'] || extra['type'] === 'route') {
                this.$router.push(route)
                setTimeout(() => {
                    location.reload()
                }, 200);
            }
            else if (extra['type'] === 'newtab')
                window.open(route)
            else if (extra['type'] === 'action')
                this.base.Security().logout()
        },
        close() {
            this.dropdown = false
        },
        onclick(id, onresult = () => { }) {
            this.base.API().post('Privacy/Signature/Register', { data: [{ mid: id, uid: Number(this.$cookies.get('uid')), sid: this.$cookies.get('identifier') }] }, (response) => {
                if (this.base.Util().success(response)) {
                    if (this.base.Util().validate(response.data.data)) {
                        let memorandum = []
                        this.memorandums.forEach((element) => {
                            if (element.id != id)
                                memorandum.push(element)
                        })
                        this.memorandums = memorandum
                    }
                }
                onresult()
            })
        }
    }
}
</script>

<style scoped src="../assets/css/dashboard.css"></style>