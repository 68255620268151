<template>
    <div class="content">
        <div class="header flex wrap center">
            <Header></Header>
        </div>
        <div class="body flex wrap center">
            <div class="row">
                <section class="overview flex wrap center">
                    <div class="right">
                        <h1>{{ entity?.title }}</h1>
                        <p>{{ entity?.extra_description }}</p>
                        <div v-if="entity?.category" class="category flex wrap start vcenter">
                            <span>{{ entity?.category }}</span>
                        </div>
                        <!-- <div class="metas flex wrap center vcenter">
                            <div class="flex wrap space vcenter">
                                <div class="flex center">
                                    <i class="fe fe-16 fe-calendar"></i>
                                    <span>{{ data?.publish_at || '' }}</span>
                                </div>
                                <div class="flex center">
                                    <i class="fe fe-16 fe-message-circle"></i>
                                    <span>{{ data?.comments || 0 }}</span>
                                </div>
                                <div class="flex center">
                                    <i class="fe fe-16 fe-eye"></i>
                                    <span>{{ data?.views || 0 }}</span>
                                </div>
                                <div class="flex center">
                                    <i class="fe fe-16 fe-star"></i>
                                    <span>{{ data?.rates || 0 }}</span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="left">
                        <img src="/images/projectbanner.webp">
                    </div>
                </section>
                <section class="main flex wrap center">
                    <section v-if="id === '1'" class="crowdfunding-content">
                        <h1>کرادفاندینگ (جمع‌آوری سرمایه)</h1>
                        <p>
                            جمع‌آوری سرمایه یا کرادفاندینگ یک مدل تأمین مالی است که در آن افراد، معمولاً از طریق
                            پلتفرم‌های آنلاین،
                            به صورت مشترک مقادیر کوچکی از پول را برای حمایت از یک پروژه، وانگاری، یا علتی مشارکت
                            می‌کنند. این مدل به عنوان
                            یک وسیله جایگزین برای تأمین سرمایه برای اقدامات مختلف از جمله شرکت‌های نوپا، پروژه‌های
                            خلاقانه، اقدامات خیریه،
                            و موارد دیگر، محبوبیت یافته است.
                        </p>

                        <h2>انواع کراد فاندینگ:</h2>

                        <ul>
                            <li><strong>پلتفرم‌های آنلاین:</strong> پلتفرم‌های تامین مالی جمعی آنلاین یا "پلتفرم‌های
                                جمع‌آوری سرمایه آنلاین" به
                                عنوان وسیله‌ای برای اتصال شرکت‌ها و افرادی که به دنبال تامین مالی برای پروژه‌ها یا
                                کسب‌وکارهای خود هستند با
                                افراد و شرکت‌های تامین مالی جمعی مشترک می‌شوند.
                            </li>
                            <li><strong>Kickstarter:</strong> Kickstarter یکی از معروف‌ترین پلتفرم‌های تامین مالی جمعی
                                آنلاین است که به خصوص برای
                                پروژه‌های خلاقانه و هنری مناسب است.
                            </li>
                            <li><strong>Indiegogo:</strong> Indiegogo یک پلتفرم جمع‌آوری سرمایه آنلاین است که برای انواع
                                پروژه‌ها و کسب‌وکارها
                                مناسب است.
                            </li>
                            <li><strong>GoFundMe:</strong> GoFundMe به افراد این امکان را می‌دهد تا برای موارد خیریه،
                                کمک به دیگران یا مسائل
                                فردی و خانوادگی سرمایه جمع‌آوری کنند.
                            </li>
                            <li><strong>Seedrs:</strong> Seedrs یک پلتفرم تامین مالی جمعی برای شرکت‌ها و کسب‌وکارهای
                                نوپا است.
                            </li>
                            <li><strong>Crowdfunder:</strong> Crowdfunder یک پلتفرم تامین مالی جمعی برای کسب‌وکارها و
                                پروژه‌های محلی و جامعه‌ای
                                است.
                            </li>
                        </ul>

                        <h2>کرادفاندینگ مبتنی بر پاداش:</h2>
                        <p>در این مدل، حامیان به پروژه وعده حمایت مالی می‌دهند و در مقابل ممکن است در صورت موفقیت پروژه
                            پاداش‌ها یا
                            محصولاتی دریافت کنند.</p>

                        <h3>مزایا و معایب تامین مالی جمعی مبتنی بر پاداش:</h3>
                        <h4>مزایای جمع‌آوری سرمایه مبتنی بر پاداش:</h4>
                        <ul>
                            <li>جذب حمایت و طرفداران</li>
                            <li>آزمایش ایده</li>
                            <li>افزایش پول</li>
                        </ul>

                        <h4>معایب جمع‌آوری سرمایه مبتنی بر پاداش:</h4>
                        <ul>
                            <li>نیاز به تعهد در پاداش‌دهی</li>
                            <li>رقابت شدید</li>
                            <li>زمان و تلاش</li>
                            <li>عدم تضمین موفقیت</li>
                            <li>مدل همه یا هیچ</li>
                        </ul>

                        <h2>کرادفاندینگ حقوقی:</h2>
                        <p>کرادفاندینگ حقوقی به حامیان اجازه می‌دهد تا در مقابل سهام یا حقوق مالکیت به شرکت سرمایه گذاری
                            کنند.</p>

                        <h2>کرادفاندینگ بدهی (Peer-to-Peer Lending):</h2>
                        <p>در این مدل، افراد به افراد دیگر یا کسب‌وکارها پول قرض می‌دهند و انتظار دارند که مبلغ اصلی به
                            همراه سود در طول
                            زمان به آنها بازگردانده شود.</p>

                        <h3>مزایا:</h3>
                        <ul>
                            <li>دسترسی به منابع مالی</li>
                            <li>انعطاف‌پذیری</li>
                            <li>جذب حمایت اجتماعی</li>
                            <li>افزایش آگاهی</li>
                        </ul>

                        <h3>معایب:</h3>
                        <ul>
                            <li>تعهدات مالی</li>
                            <li>مسائل حقوقی</li>
                            <li>ریسک شکست</li>
                            <li>انتخاب نادرست شرکت‌کنندگان</li>
                        </ul>

                        <h2>کرادفاندینگ بر اساس اهداء:</h2>
                        <p>در کرادفاندینگ بر اساس اهداء، مشارکت‌کنندگان اقدامات خیریه یا کمپین‌های شخصی را با دانستن
                            اینکه سرمایه برنمی
                            گردد حمایت می‌کنند.</p>

                        <h3>مزایای تامین مالی جمعی مبتنی بر اهدا:</h3>
                        <ul>
                            <li>جذب تمایلات و اعتبار</li>
                            <li>عدم از دست دادن سهام</li>
                            <li>تسهیل فرآیند جذب سرمایه</li>
                        </ul>

                        <h3>معایب تامین مالی جمعی مبتنی بر اهدا:</h3>
                        <ul>
                            <li>عدم اطمینان از تامین مالی</li>
                            <li>هزینه‌های کمپین</li>
                        </ul>
                    </section>
                    <section v-if="id === '2'" class="crowdfunding-content">
                        <h1>تفاوت‌های کرادفاندینگ با دیگر روش‌های تأمین مالی</h1>

                        <p>در این پست می‌خواهیم بپردازیم به تفاوت‌های کرادفاندینگ با بقیه روش‌های تأمین مالی:</p>

                        <h2>1. تامین مالی جمعی</h2>
                        <ul>
                            <li>در تامین مالی جمعی، سرمایه از طریق تجمیع وجود دارنده‌های مالکیت پروژه یا کسب و کار تامین
                                می‌شود.</li>
                            <li>افراد و شرکت‌ها می‌توانند به عنوان مالکان جدید با پروژه یا کسب و کار همکاری کنند و وامی
                                را به عنوان بدهی بازپرداخت‌پذیر بگیرند.</li>
                            <li>مالکیت اصلی پروژه یا کسب و کار با افراد جدید تقسیم می‌شود و آنها به عنوان سهامداران جدید
                                وارد می‌شوند.</li>
                        </ul>

                        <h2>2. تامین مالی مبتنی بر جذب فروختن سهام</h2>
                        <ul>
                            <li>در تامین مالی مبتنی بر جذب فروختن سهام، سهام موجود در شرکت به عنوان واحد تجاری فروخته
                                می‌شوند و نسبت مالکیت تغییر می‌کند.</li>
                            <li>این روش معمولاً با صدور سهام جدید به عنوان اوراق بهادار به عموم مردم یا سرمایه‌گذاران
                                انجام می‌شود.</li>
                            <li>مالکیت اصلی شرکت تغییر نمی‌کند و مالکان موجود همچنان سهامدار می‌مانند. افراد جدید تنها
                                سهامداران جدید هستند.</li>
                        </ul>

                        <p>بنابراین، تفاوت اصلی بین این دو روش در تغییر مالکیت و تقسیم مالکیت است. تامین مالی جمعی منجر
                            به تجمیع مالکیت با افراد و شرکت‌های جدید می‌شود، در حالی که تامین مالی مبتنی بر جذب فروختن
                            سهام مالکیت اصلی شرکت را حفظ می‌کند و تغییرات در مالکیت توسط صدور سهام جدید اتفاق می‌افتد.
                            هر یک از این روش‌ها مزایا و معایب خود را دارند و انتخاب بین آنها بستگی به نیازها و اهداف
                            مالی شرکت یا پروژه دارد.</p>

                        <h2>تامین مالی جمعی و تعاونی</h2>
                        <p>تامین مالی جمعی (Crowdfunding) و تعاونی (Cooperative) دو روش مختلف برای جمع‌آوری سرمایه
                            هستند، و هر کدام ویژگی‌ها و مزایا و معایب خود را دارند. در زیر تفاوت‌های اصلی بین این دو روش
                            تامین مالی توضیح داده شده است:</p>

                        <h3>1. ساختار و مالکیت</h3>
                        <ul>
                            <li><strong>تامین مالی جمعی:</strong> در تامین مالی جمعی، سرمایه‌گذاران (عموماً افراد و
                                شرکت‌ها) به عنوان اعضای جمعی شرکت‌کننده در تامین مالی پروژه یا کسب و کار شناخته می‌شوند.
                                آن‌ها سرمایه مالی را ارائه می‌دهند و ممکن است در مقابل آن، سهمی از سود یا محصول نهایی را
                                دریافت کنند، اما نقش فعال در مدیریت کسب و کار دارند.</li>
                            <li><strong>تعاونی:</strong> در تعاونی، اعضا شرکت کنندگان عادی هستند که به صورت مشترک و
                                تعاونی یک کسب و کار را مدیریت می‌کنند. آن‌ها ممکن است سرمایه‌گذاری کنند، اما مالکیت و
                                مدیریت کسب و کار توسط اعضا تعاونی تعیین می‌شود. اعضا از سود و مزایای کسب و کار به صورت
                                متناسب با مشارکت خود بهره‌مند می‌شوند.</li>
                        </ul>

                        <h3>2. حقوق و مسائل مالی</h3>
                        <ul>
                            <li><strong>تامین مالی جمعی:</strong> در تامین مالی جمعی، سرمایه‌گذاران عموماً به عنوان
                                اعضای جمعی مشخص می‌شوند و از نظر حقوقی به عنوان سرمایه‌گذاران تلقی می‌شوند. آن‌ها ممکن
                                است سودی از پروژه یا کسب و کار دریافت کنند ولی نه به عنوان مالکان واقعی.</li>
                            <li><strong>تعاونی:</strong> در تعاونی، اعضا معمولاً مالکان واقعی کسب و کار هستند و دارای
                                حقوق تصمیم‌گیری در مورد مدیریت و عملکرد کسب و کار هستند. اعضا به صورت مشترک تصمیم‌گیری
                                می‌کنند و سود و ضررهای کسب و کار به تساوی توزیع می‌شوند.</li>
                        </ul>

                        <h3>3. هدف</h3>
                        <ul>
                            <li><strong>تامین مالی جمعی:</strong> هدف اصلی تامین مالی جمعی اغلب تأمین منابع مالی برای یک
                                پروژه یا کسب و کار است. سرمایه‌گذاران عموماً به عنوان افرادی که علاقه به حمایت از یک
                                ایده یا پروژه دارند شناخته می‌شوند.</li>
                            <li><strong>تعاونی:</strong> تعاونی‌ها اغلب بر اساس اصول تعاون و مشارکت ایجاد می‌شوند. هدف
                                اصلی تعاونی‌ها تعامل مشترک اعضا برای ایجاد یک ساختار مشترک است که به تساوی مزایا و خدمات
                                را فراهم کند.</li>
                        </ul>

                        <p>در کل، تامین مالی جمعی و تعاونی دو رویکرد مختلف به تأمین مالی و مدیریت کسب و کار یا پروژه
                            هستند، و از نظر ساختار و حقوقی متفاوتی دارند. انتخاب بین این دو روش وابسته به هدف و نیاز شما
                            و اهداف کسب و کار یا پروژه شما است.</p>
                    </section>
                    <aside class="sidebar">
                    </aside>
                </section>
                <section class="footer"></section>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
/* eslint-disable */
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
    name: 'Post',
    props: ['id'],
    inject: ['base'],
    components: { Header, Footer },
    created() {
        if (this.id === '1') {
            this.entity.title = 'جمع‌آوری سرمایه یا کرادفاندینگ'
            this.entity.extra_description = 'جمع‌آوری سرمایه یا کرادفاندینگ یک مدل تأمین مالی است که در آن افراد، معمولاً از طریق پلتفرم‌های آنلاین، به صورت مشترک مقادیر کوچکی از پول را برای حمایت از یک پروژه، وانگاری، یا علتی مشارکت می‌کنند. این مدل به عنوان یک وسیله جایگزین برای تأمین سرمایه برای اقدامات مختلف از جمله شرکت‌های نوپا، پروژه‌های خلاقانه، اقدامات خیریه، و موارد دیگر، محبوبیت یافته است.'
            this.entity.category = 'مرکز آموزش آتیاکراد'
        } else if (this.id === '2') {
            this.entity.title = 'کرادفاندینگ یا تامین مالی'
            this.entity.extra_description = `سلام وقتتون بخیر تو این پست میخوایم بپردازیم به تفاوت های کراد فانیدنگ با بقیه روش های تامین مالی:

تامین مالی جمعی و تامین مالی مبتنی بر جذب فروختن سهام (یا به عبارت دقیق‌تر، اقدامات به انتشار عام سهام) دو روش متفاوت برای تامین سرمایه برای یک کسب و کار یا پروژه هستند.`
            this.entity.category = 'دانشنامه آتیا'
        }
    },
    data() {
        return {
            entity: {},
            data: {},
            models: {}
        }
    }
}
</script>

<style scoped src="../assets/css/views/post.css"></style>

<style scoped>
/* Container for the content */
.crowdfunding-content {
    max-width: 900px;
    /* margin: 20px auto; */
    /* padding-top: 0px !important; */
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Styling for headers */
h1,
h2,
h3,
h4 {
    color: #3f51b5;
    text-align: center;
    margin-bottom: 10px;
}

/* Styling for paragraphs */
p {
    font-size: 1.1rem;
    margin-bottom: 15px;
    text-align: justify;
}

/* Styling for lists */
ul {
    list-style-type: disc;
    margin-left: 20px;
}

ul li {
    margin-bottom: 10px;
}

/* Style for strong elements */
strong {
    color: #333;
}

/* Small heading styles */
h3 {
    color: #5f6368;
}

h4 {
    color: #8e8e8e;
    margin-bottom: 10px;
}

/* Add spacing between sections */
section+section {
    margin-top: 30px;
}

/* Optional: Add a footer for the page */
footer {
    text-align: center;
    /* margin-top: 50px; */
    color: #999;
}
</style>