<template>
    <div class="content">
        <div class="header flex wrap center">
            <Header></Header>
        </div>
        <div class="body flex wrap center">
            <div class="row">
                <Tabs :meta="{
                    type: 'step',
                    extras: 'R0:key=profit,value=مزایای تأمین سرمایه از آتیه ایرانیان;R1:key=process,value=فرآیند تأمین سرمایه از آتیه ایرانیان;R2:key=rule,value=قوانین و مقررات تأمین سرمایه;R3:key=request,value=ثبت درخواست تأمین سرمایه;R4:key=faq,value=سوالات متداول درباره سرمایه گذاری'
                }" :vm="models" @onchange="onchange"></Tabs>
                <div class="container">
                    <div v-if="models === 'profit'">
                        <div class="introduction flex wrap center vcenter">
                            <div class="row">
                                <div class="head flex wrap center vcenter">
                                </div>
                                <div class="body flex wrap center vcenter">
                                    <template v-for="(item, index) in introductions" :key="index">
                                        <div class="flex wrap center vcenter">
                                            <div class=" flex wrap center vcenter"><img class="symbol"
                                                    :src="item['image']" alt="" />
                                            </div>
                                            <h4 class="title">{{ item['title'] }}</h4>
                                            <span class="description">{{ item['description'] }}</span>
                                        </div>
                                    </template>
                                </div>
                                <div class="footer flex wrap center vcenter">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="models === 'process'" id="services" class="services flex wrap center vcenter">
                        <div class="row">
                            <div class="head flex wrap center">
                            </div>
                            <div class="body flex wrap center vcenter">
                                <template v-for="(stage, index) in 11" :key="index">
                                    <div class="step flex vend">
                                        <div class="symbol flex wrap center vcenter">
                                            <img :src="icons[stage - 1]" />
                                        </div>
                                        <div class="text">
                                            <h4>{{ processes[stage - 1] }}</h4>
                                        </div>
                                        <h4 class="stage"
                                            :class="[{ 'c1': [1, 4, 7].includes(stage) }, { 'c2': [2, 5, 8].includes(stage) },
                                            { 'c3': [3, 6, 9].includes(stage) }, { 'c4': stage === 10 }, { 'c5': stage === 11 }]">
                                            {{ stage }}
                                        </h4>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="footer flex wrap center vcenter">
                        </div>
                    </div>
                    <div v-else-if="models === 'rule'" class="register">
                        <p>شروط الزامی جهت بررسی درخواست تأمین مالی توسط آتیه ایرانیان عبارت‌اند از این که شرکت باید:
                        </p>
                        <ul>
                            <li>
                                <p>متقاضی تأمین سرمایه لزوما می بایست شخص حقوقی ثبت شده باشد.</p>
                            </li>
                            <li>
                                <p>از تأسیس شخصیت حقوقی حداقل یکسال گذشته باشد.</p>
                            </li>
                            <li>
                                <p>دارای اظهارنامه رسمی و صورت های مالی حسابرسی شده باشد.</p>
                            </li>
                            <li>
                                <p>درآمد سالیانه بالای 2 میلیارد تومان داشته باشد.</p>
                            </li>
                            <li>
                                <p>در سال مالی گذشته الزاما سودده باشد.</p>
                            </li>
                            <li>
                                <p>مشمول ماده 141 قانون تجارت نباشد.</p>
                            </li>
                            <li>
                                <p>فاقد هرگونه چک برگشتی و معوقات بانکی (مدیرعامل و هیئت مدیره و صاحبین امضاء) باشد.</p>
                            </li>
                        </ul>
                    </div>
                    <div v-else-if="models === 'request'" class="register">
                        <p>خواهشمند است جهت ثبت درخواست اولیه تأمین سرمایه از طریق سکوی تأمین مالی جمعی آتیه ایرانیان
                            ابتدا
                            در صورتی که حساب
                            کاربری ندارید، ابتدا به عنوان شخص حقوقی ثبت نام کرده و سپس فرم درخواست تأمین سرمایه را تکمیل
                            نمایید، کارشناسان
                            آتیه ایرانیان در اولین فرصت نسبت به بررسی پروژه اقدام و جهت انجام مراحل بعدی با شما تماس
                            خواهند
                            گرفت.</p>
                        <Button
                            :meta="{ key: 'redirect', name: 'پیوستن به خانواده آتیا', style: JSON.stringify({ 'width': '30%' }) }"
                            @onclick="$router.push('/Authentication')"></Button>
                    </div>
                    <div v-else-if="models === 'faq'" class="faq">
                        <p>جهت سهولت در فرآیند کار با سکوی تأمین مالی جمعی آتیه ایرانیان ، تعدادی
                            از
                            سوالات متداول شما در
                            بخش ذیل گردآوری شده؛ در صورت وجود هرگونه ابهام در رابطه با تأمین مالی لطفا به
                            پرتال کاربری مراجعه و
                            نسبت به ثبت تیکت پشتیبانی اقدام فرمایید.</p>
                        <Accordion :title="'آتیه ایرانیان چیست؟'"
                            :description="' تامین مالی جمعی آتیه ایرانیان ، سکو تامین مالی جمعی دارای مجوز از فرابورس ایران به شماره مجوز و ثبت شده نزد اداره ثبت شرکتها و موسسات غیرتجاری استان تهران به شماره ثبتی 570651 می باشد که با اتکا بر دانش و تجربه مدیران، مشاوران و متخصصان حوزه کسب و کار و بازار پول و سرمایه به شناسایی طرح ها و پروژه های مولد و سودزا و تامین سرمایه مورد نیاز آنها می نماید، شایان ذکر است این مجموعه با استفاده از ظرفیت های موجود در کشور ضمن توجه ویژه به حوزه دانش بنیان و کسب و کار های نوپا در تلاش است بستری امن را برای سرمایه گذاران و سرمایه پذیران فعالان حوزه اقتصادی فراهم نماید. '">
                        </Accordion>
                        <!-- <Accordion :title="'روش کار آتیه ایرانیان چگونه است؟'"></Accordion>
                        <Accordion :title="'مراحل دریافت خدمات تأمین مالی جمعی برای متقاضیان سرمایه چگونه است؟'">
                        </Accordion>
                        <Accordion :title="'برای ارزیابی طرح‌ها در آتیه ایرانیان چه مستنداتی لازم است؟'"></Accordion>
                        <Accordion :title="'هزینه استفاده از خدمات تأمین مالی جمعی برای متقاضیان چقدر است؟'">
                        </Accordion> -->
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
/* eslint-disable */
import Header from '../components/Header.vue'
import Tabs from '../components/Inputs/Tabs.vue'
import Accordion from '../components/Accordion.vue'
import Button from '../components/Inputs/Button.vue'
import Footer from '../components/Footer.vue'

export default {
    name: 'Funding',
    components: { Header, Tabs, Accordion, Button, Footer },
    data() {
        return {
            models: 'profit',
            introductions: [
                {
                    title: 'تأمین سرمایه سریع',
                    description: '',
                    image: '/images/icons/i_1.png'
                },
                {
                    title: 'سهولت در تأمین مالی',
                    description: '',
                    image: '/images/icons/i_19.png'
                },
                {
                    title: 'توسعه شبکه مشتریان',
                    description: '',
                    image: '/images/icons/i_11.png'
                },
                {
                    title: 'شهرت و توسعه برند',
                    description: '',
                    image: '/images/icons/i_3.png'
                },
                {
                    title: 'شرایط ویژه برای شرکت‌های دانش بنیان',
                    description: '',
                    image: '/images/icons/i_15.png'
                }],
            processes: ['ثبت درخواست'
                , 'بررسی اولیه توسط کارشناسان'
                , 'اعتبار سنجی طرح'
                , 'عقد قرارداد با متقاضی'
                , 'اخذ وثایق از متقاضی'
                , 'دریافت تائیدیه از نهاد مالی'
                , 'دریافت تائیدیه از فرابورس'
                , 'تأمین سرمایه از سرمایه گذاران'
                , 'پرداخت وجه تأمین شده'
                , 'نظارت برفرآیند اجرای تعهدات'
                , 'دریافت سود و اصل سرمایه'],
            icons: [
                'images/icons/i_18.png'
                , 'images/icons/i_8.png'
                , 'images/icons/i_3.png'
                , 'images/icons/i_21.png'
                , 'images/icons/i_13.png'
                , 'images/icons/i_11.png'
                , 'images/icons/i_17.png'
                , 'images/icons/i_7.png'
                , 'images/icons/i_1.png'
                , 'images/icons/i_9.png'
                , 'images/icons/i_20.png']
        }
    },
    methods: {
        onchange(obj) {
            this.models = obj.value
        }
    }
}
</script>

<style scoped src="../assets/css/funding.css"></style>