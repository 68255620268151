<template>
    <div class="content">
        <div id="particles-js">
        </div>
        <div class="flex wrap center">
            <div class="body flex wrap center">
                <div class="login flex wrap center" :class="[{ 'rotate': animate }, { 'rerotate': reverse }
                    , { 'subscription': position === 'subscription' }, { 'finished': message }]">
                    <form v-if="position !== 'subscription'" class="flex wrap center vcenter" @:submit.prevent="">
                        <div class="brand flex center vcenter">
                            <img class="logo" src="../assets/images/icon.png" />
                        </div>
                        <div class="brand flex center vcenter">
                            <img class="title" src="../assets/images/atiyacrowd.png" />
                        </div>
                        <span class="subtitle">{{ models['title'][position] }}</span>
                        <template v-for="(placeholder, index) in models['placeholders']
                            .filter((placeholder) => { return (placeholder.position.includes(position)) })"
                            :key="index">
                            <MetaBox :meta="{
                                key: placeholder.key,
                                name: placeholder.content,
                                type: placeholder.key === 'mobile' ? 'mobile' : 'text'
                            }" :vm="models[placeholder.key]" :validate="errors[placeholder.key]"
                                :disabled="disabled[placeholder.key]" :ctrl="ctrl" @onchange="onchange">
                            </MetaBox>
                        </template>
                        <div v-if="position === 'verify'" class="status">
                            <span>{{ status }}</span>
                            <a v-if="span <= 0" @click.prevent=" authenticate('resend')">ارسال مجدد</a>
                        </div>
                        <div class="button">
                            <button :class="{ 'active': loading['submit'] }" type="button"
                                @click.prevent="authenticate()">
                                <div class="loading">
                                    <span></span>
                                </div>
                                <p>{{ models['submit'][position] }}</p>
                            </button>
                        </div>
                        <span class="message">
                            {{ message }}
                        </span>
                    </form>
                    <Form v-else-if="!message" :entity_type="'ACCOUNT'" :entity="{
                        title: 'ثبت نام',
                        options: 'R0:key=button,value=register'
                    }" @onresult="onresult">
                    </Form>
                    <div v-if="position === 'subscription' && message" class="congratulation">
                        <div class="head">
                            <div class="title">
                                <h1>تبریک!</h1>
                            </div>
                        </div>
                        <span class="message">
                            {{ message }}
                        </span>
                        <div v-show="authenticated" class="options flex wrap center vcenter">
                            <button type="button" @click="route()">
                                <p>{{ 'برو به پرتال کاربری من' }}</p>
                            </button>
                        </div>
                    </div>
                    <div class="footer flex center vcenter">
                        <a href="/" @click.prevent="change">{{ models['toggle'][position] }}</a>
                    </div>
                </div>
                <div v-if="position !== 'subscription'" class="help flex wrap center">
                    <div class="flex wrap center">
                        <span>
                            ثبت نام افراد حقیقی با وارد نمودن شماره ملی (یا کد فراگیر) و شماره موبایل ثبت نام شده در
                            سامانه سجام صورت می
                            گیرد در صورتی که قبلا در سجام ثبت نام نکرده اید <a
                                href="https://profilesejam.csdiran.ir/session" target="_blank">اینجا</a> کلیک نمایید.
                            <br>
                            ثبت نام اشخاص حقوقی با وارد نمودن شناسه ملی شخص حقوقی و شماره موبایل نماینده قانونی وی صورت
                            می گیرد ، ثبت نام در
                            سجام برای شخص حقوقی و نماینده وی الزامی است.
                        </span>
                    </div>
                </div>
            </div>
            <div class="footer flex center vcenter">
                <div class="right flex start vcenter">
                    <a href="tel:+982591004810" target="_blank">پشتیبانی : 91004810-025</a>
                </div>
                <div class="center flex center vcenter">
                    <a href="https://atiyacrowd.ir/" target="_blank">سایت آتیه ایرانیان</a>
                </div>
                <div class="left flex end vcenter">
                    <a>تمامی حقوق مادی و معنوی برای آتیه‌ ایرانیان محفوظ است. © 2024</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import MetaBox from '../components/Inputs/MetaBox.vue'
import Form from '../components/Form.vue'

require('../assets/js/particles.js')

export default {
    name: 'Authentication',
    inject: ['base'],
    components: { MetaBox, Form },
    mounted() {
        particlesJS("particles-js", {
            "particles": {
                "number": {
                    "value": 100,
                    "density": {
                        "enable": true,
                        "value_area": 800
                    }
                },
                "color": {
                    "value": "#2fbbd1"
                },
                "shape": {
                    "type": "circle",
                    "stroke": {
                        "width": 7.5,
                        "color": "#2fbbd1"
                    },
                    "polygon": {
                        "nb_sides": 5
                    },
                    "image": {
                        "src": "img/github.svg",
                        "width": 100,
                        "height": 100
                    }
                },
                "opacity": {
                    "value": 0.5,
                    "random": false,
                    "anim": {
                        "enable": false,
                        "speed": 1,
                        "opacity_min": 0.1,
                        "sync": false
                    }
                },
                "size": {
                    "value": 2,
                    "random": true,
                    "anim": {
                        "enable": false,
                        "speed": 40,
                        "size_min": 0.1,
                        "sync": false
                    }
                },
                "line_linked": {
                    "enable": true,
                    "distance": 150,
                    "color": "#2fbbd1",
                    "opacity": 0.5,
                    "width": 1
                },
                "move": {
                    "enable": true,
                    "speed": 2,
                    "direction": "none",
                    "random": false,
                    "straight": false,
                    "out_mode": "out",
                    "bounce": false,
                    "attract": {
                        "enable": false,
                        "rotateX": 600,
                        "rotateY": 1200
                    }
                }
            },
            "interactivity": {
                "detect_on": "canvas",
                "events": {
                    "onhover": {
                        "enable": true,
                        "mode": "grab"
                    },
                    "onclick": {
                        "enable": true,
                        "mode": "push"
                    },
                    "resize": true
                },
                "modes": {
                    "grab": {
                        "distance": 150,
                        "line_linked": {
                            "opacity": 1
                        }
                    },
                    "bubble": {
                        "distance": 400,
                        "size": 40,
                        "duration": 2,
                        "opacity": 8,
                        "speed": 3,
                    },
                    "repulse": {
                        "distance": 200,
                        "duration": 0.4
                    },
                    "push": {
                        "particles_nb": 4,
                    },
                    "remove": {
                        "particles_nb": 2
                    }
                }
            },
            "retina_detect": true
        })
        requestAnimationFrame(() => { })
    },
    created() {
        this.init()
    },
    data() {
        return {
            position: 'login',
            previous: '',
            models: {
                title: { login: 'ورود', verify: 'تائید شماره همراه', register: 'عضویت' },
                placeholders: [{ key: 'username', position: 'login|register', content: 'شناسه ملی' }
                    , { key: 'verification', position: 'verify', content: 'کد تائید' }
                    , { key: 'mobile', position: 'register', content: 'شماره همراه' }],
                submit: { login: 'احراز هویت', verify: 'ورود به سامانه', register: 'تائید و ثبت نام' },
                toggle: { login: 'ایجاد حساب کاربری', verify: 'ایجاد حساب کاربری', register: 'ورود به پرتال مشتریان' },
            },
            errors: {},
            disabled: {},
            status: '',
            span: 0,
            message: '',
            loading: {},
            animate: false,
            reverse: false,
            authenticated: false
        }
    },
    watch: {
        models: {
            handler(newModels, oldModels) {
                if (this.position === 'verify' && newModels['verification'] && newModels['verification'].length === 5)
                    this.authenticate()

                Object.keys(this.models).forEach(key => {
                    if (!this.models[key] || this.models[key] === 'undefined')
                        delete this.models[key]
                })
            },
            deep: true
        },
        position(newPosition, oldPosition) {
            this.previous = oldPosition
            if (newPosition === 'verify')
                this.timer('start', this.span ? this.span : 60)
        }
    },
    methods: {
        init() {
            this.message = this.$cookies.get('message')
            setInterval(() => {
                if (!this.base.Util().validate(this.message))
                    this.message = this.$cookies.get('message')
            }, 100)
            this.$cookies.remove('message')
        },
        onchange(obj) {
            this.models[obj.key] = obj.value
            this.errors[obj.key] = obj.error
        },
        authenticate(action = this.position) {
            let pass = true
            if (action !== 'resend')
                this.models.placeholders.forEach((entity) => {
                    this.errors[entity.key] = entity.position.includes(this.position)
                        && !this.base.Util().validate(this.models[entity.key])
                    if (this.errors[entity.key]) {
                        this.base.Notification().error({
                            code: 1000
                            , text: entity.content
                        })
                        pass = false
                    }
                })

            if (pass) {
                this.loading['submit'] = true
                let token = this.$cookies.get('otoken')

                this.message = ''
                this.$cookies.remove('message')

                if (action === 'login' || action === 'register' || action === 'resend')
                    this.base.API().get((this.position === 'login' || action === 'resend') && (this.position === 'login' || (action === 'resend' && this.previous !== 'register'))
                        ? `Authentication/Authorize/Login?key=${this.models['username']}&otoken=${this.base.Util().validate(token) ? token : ''}`
                        : `Authentication/Authorize/Lookup?key=${this.models['username']}&otoken=${this.base.Util().validate(token) ? token : ''}&receptor=${this.models['mobile']}`, (response) => {
                            if (this.base.Util().success(response)) {
                                if (response.data.otoken)
                                    this.$cookies.set('otoken', response.data.otoken)
                                this.span = response.data.span && response.data.span > 0
                                    ? response.data.span : 60
                                this.position = 'verify'
                                if (action === 'resend')
                                    this.timer('start')
                            } else
                                this.base.Notification().error({ code: response.data.errorCode, referr: 'login' })
                            delete this.loading['submit']
                        }, () => delete this.loading['submit'])
                else if (this.previous === 'register' && action === 'verify') {
                    this.base.API().get(`Authentication/Authorize/GetUser?key=${this.models['username']}&receptor=${this.models['mobile']}&code=${this.models['verification']}`, (response) => {
                        if (this.base.Util().success(response)) {
                            if (this.base.Util().validate(response.data.accesses)
                                && this.base.Util().validate(response.data.metas)) {
                                localStorage.setItem('accesses', JSON.stringify(response.data.accesses))
                                localStorage.setItem('metas', JSON.stringify(response.data.metas))

                                this.position = 'subscription'
                            }
                        } else
                            this.base.Notification().error({ code: response.data.errorCode, referr: 'login' })
                        delete this.loading['submit']
                    }, () => delete this.loading['submit'])
                }
                else
                    this.base.API().post(`Authentication/OTP/Verify?token=${token}&username=${this.models['username']}&otp=${this.models['verification']}&action=${this.previous === 'login' ? 'ACCOUNT' : 'DATA'}`
                        , this.base.Security().session(), (response) => {
                            if (this.base.Util().success(response)) {
                                if (response.data.uid && response.data.gid && response.data.rid
                                    && this.base.Util().validate(response.data.data)) {

                                    this.$cookies.config(`${response.data.age}min`)

                                    this.$cookies.set('uid', response.data.uid)
                                    this.$cookies.set('gid', response.data.gid)
                                    this.$cookies.set('rid', response.data.rid)
                                    this.$cookies.set('grade', response.data.grade)
                                    this.$cookies.set('identifier', response.data.identifier)

                                    if (this.base.Util().validate(response.data.accesses))
                                        localStorage.setItem('accesses', JSON.stringify(response.data.accesses))

                                    if (this.base.Util().validate(response.data.categories)
                                        && this.base.Util().validate(response.data.menus))
                                        localStorage.setItem('modules', JSON.stringify([{
                                            categories: response.data.categories.sort((m1, m2) => (m1.order > m2.order) ? 1 : (m1.order < m2.order) ? -1 : 0),
                                            menus: response.data.menus.sort((m1, m2) => (m1.order > m2.order) ? 1 : (m1.order < m2.order) ? -1 : 0),
                                            options: response.data.options.sort((m1, m2) => (m1.order > m2.order) ? 1 : (m1.order < m2.order) ? -1 : 0),
                                        }]))

                                    if (this.base.Util().validate(response.data.metas)) {
                                        localStorage.setItem('metas', JSON.stringify(response.data.metas))
                                        if (this.base.Util().validate(response.data.stages))
                                            localStorage.setItem('stages', JSON.stringify(response.data.stages))
                                    }

                                    this.$cookies.set('display_name', response.data.data.split(';')[0])
                                    this.$cookies.set('username', response.data.data.split(';')[1])

                                    this.$router.push({ path: this.$route.query['return'] || '/Dashboard' })
                                } else if (this.base.Util().validate(response.data.metas)
                                    && this.base.Util().validate(response.data.accesses)) {
                                    localStorage.setItem('metas', JSON.stringify(response.data.metas))
                                    localStorage.setItem('accesses', JSON.stringify(response.data.accesses))

                                    this.position = 'subscription'
                                }
                                else if (response.data.errorCode === 109)
                                    this.message = `${response.data.message} عزیز؛ \nدرحال حاضر حساب کاربری شما در انتظار بررسی توسط همکاران ما می‌باشد. از صبر و شکیبائی شما سپاسگزاریم`
                                else
                                    this.base.Notification().toast('خطا', 'احراز هویت با خطا مواجه شد', 'error')
                            } else
                                this.base.Notification().error({ code: response.data.errorCode, referr: 'verify' })
                            delete this.loading['submit']
                        }, () => delete this.loading['submit'])
            }
        },
        change() {
            if (this.animate) {
                this.animate = false
                this.reverse = true
            }
            else {
                this.reverse = false
                this.animate = true
            }

            setTimeout(() => {
                if (this.position === 'register')
                    this.position = this.previous
                else
                    this.position = 'register'
            }, 284)
        },
        timer(action = 'refresh', extra = 60) {
            if (action === 'start') {
                this.span = extra
                this.timer('status')
            } else if (action === 'status') {
                this.status = `${this.span} ثانیه تا ارسال مجدد کد تأیید`
                this.timer()
            } else if (action === 'refresh') {
                if (this.span > 0) {
                    setTimeout(() => {
                        this.span -= 1
                        this.timer('status')
                    }, 1000)
                } else
                    this.status = 'کد تأیید را دریافت نکردید؟'
            }
        },
        onresult(obj) {
            if (this.base.Util().success({ data: obj })) {
                let display_name = obj.data.find((value) => value.key === 'display_name').value
                let username = obj.data.find((value) => value.key === 'username').value
                this.message = `متقاضی گرامی ${display_name} ، ثبت نام اولیه شما در پلتفرم تامین مالی جمعی آتیه ایرانیان، آتیا کراد ، با شماره، شناسه ملی / کد اتباع ${username} و شماره موبایل ${obj.data.find((value) => value.key === 'mobile').value} انجام شد.
کارشناسان ما به زودی و در اسرع وقت جهت تکمیل ثبت نام و راهنمایی های بعدی، با شما تماس خواهند گرفت.

هم اکنون میتوانید ضمن ورود به پرتال کاربری خود، از خدمات آتیه ایرانیان استفاده نمایید.

در صورتی که نیاز به پشتیبانی و پیگیری ثبت نام دارید میتوانید همه روزه و در هر ساعت از شبانه روز با مرکز تماس آتیا به شماره 02591004810 تماس حاصل فرمایید.`

                this.$cookies.set('uid', obj.data[0].parent_id.replace('AC', ''))
                this.$cookies.set('gid', obj.data.find((value) => value.key === 'group').value.replace('GR', ''))
                this.$cookies.set('rid', obj.data.find((value) => value.key === 'role').value.replace('RO', ''))

                this.$cookies.set('display_name', display_name)
                this.$cookies.set('username', username)

                setTimeout(() => {
                    this.base.API().post('Security/Session/Register', { data: [this.base.Security().session().data] },
                        (response) => {
                            if (this.base.Util().success(response)) {
                                this.$cookies.set('identifier', response.data.data[0].token)
                                this.base.API().post('Identities/Role/Find', { data: { id: Number(this.$cookies.get('rid')) } }, (response) => {
                                    if (this.base.Util().success(response)) {
                                        if (this.base.Util().validate(response.data.data)) {
                                            this.$cookies.set('grade', response.data.data
                                                .find((value) => value.key === 'grade').value)
                                            this.base.API().get(`Security/Access/Produce?uid=${this.$cookies.get('uid')}&gid=${this.$cookies.get('gid')}&rid=${this.$cookies.get('rid')}`
                                                , (response) => {
                                                    if (this.base.Util().success(response)) {
                                                        if (this.base.Util().validate(response.data.accesses))
                                                            localStorage.setItem('accesses', JSON.stringify(response.data.accesses))

                                                        if (this.base.Util().validate(response.data.categories, response.data.menus)
                                                            && this.base.Util().validate(response.data.menus)) {
                                                            localStorage.setItem('modules', JSON.stringify([{
                                                                categories: response.data.categories,
                                                                menus: response.data.menus,
                                                                options: response.data.options,
                                                            }]))
                                                            this.authenticated = true
                                                        } else {
                                                            localStorage.setItem('accesses', JSON.stringify([]))
                                                            localStorage.setItem('modules', JSON.stringify([]))
                                                        }
                                                    }
                                                })
                                        }
                                        else
                                            this.$cookies.remove('grade')
                                    }
                                })
                            }
                        }, (error) => location.reload())
                }, 2000)
            }
        },
        route() {
            this.$router.push('/Dashboard')
        }
    }
}
</script>

<style scoped src="../assets/css/authentication.css"></style>