<template>
    <div class="content">
        <div class="header flex wrap center">
            <Header></Header>
        </div>
        <div class="body flex wrap center">
            <div class="row">
                <div class="banner">
                    <img src="/images/test.jpeg" />
                </div>
                <div class="details flex wrap center">
                    <div class="right flex wrap center">
                        <div class="tab flex wrap center">
                            <p>اطلاعات سرمایه گذاری</p>
                        </div>
                        <div class="overview flex wrap center">
                            <div class="identity">
                                <p>نماد طرح: {{ find('symobl') }}</p>
                                <p>نام طرح: {{ find('folder_name') }}</p>
                                <p>سرمایه پروژه: {{ find('amount') }}</p>
                                <p>یش بینی سود: {{ find('interest_rate') }}</p>
                                <p>دوره سرمایه گذاری: {{ find('period') }}</p>
                                <p>نحوه تسویه سود: {{ find('dividend_period') }}</p>
                                <p>نحوه تسویه اصل: {{ find('repayment_model') }}</p>
                                <p>وثایق و تضامین: ضمانت نامه بانکی</p>
                                <p>شناسه طرح نزد سازمان فرابورس: <a href="https://cf.ifb.ir/home/ViewProject"
                                        target="_blank">جهت استعلام طرح کلیک کنید</a></p>
                            </div>
                            <hr>
                            <Progress :total="find('amount')" :count="count" :amount="String(amount).replace(/\s/g, '').replace(/,/g, '')
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')"></Progress>
                            <button class="flex wrap center vcenter" @click.prevent="this.$cookies.get('uid')
                                ? $router.push(`/In/${id}`)
                                : $router.push('/Authentication?return=/Project/3')">
                                <i class=" fe fe-16 fe-check flex wrap center vcenter"></i>
                                <p>سرمایه گذاری میکنم!</p>
                            </button>
                            <button class="flex wrap center vcenter" @click.prevent="this.$cookies.get('uid')
                                ? like()
                                : $router.push('/Authentication?return=/Project/3')"
                                style="background-color: crimson;">
                                <i class="fe fe-16 fe-heart flex wrap center vcenter c"></i>
                                <p>علاقه مند به سرمایه گذاری</p>
                            </button>
                        </div>
                    </div>
                    <div class="left flex wrap center">
                        <div class="tt">
                            <Tabs :meta="{
                                type: 'step',
                                extras: 'R0:key=intro,value=معرفی طرح;R1:key=finance,value=اطلاعات مالی;R2:key=person,value=اطلاعات متقاضی;R2:key=process,value=گزارش پیشرفت;R3:key=i,value=سرمایه گذاران;R4:key=p,value=شکایات;R5:key=k,value=ماشین حساب'
                            }" :vm="models" @onchange="onchange"></Tabs>
                        </div>
                        <div class="container">
                            <div v-if="models === 'process'" class="cc">
                                <p>
                                    {{ p1 }}
                                </p>
                            </div>
                            <div v-if="models === 'i'" class="cc">
                                <p>
                                    {{ p2 }}
                                </p>
                            </div>
                            <div v-if="models === 'intro'" class="cc">
                                <p>
                                    {{ p3 }}
                                </p>
                            </div>
                            <div v-if="models === 'finance'" class="cc">
                                <p>نماد طرح: {{ find('symobl') }}</p>
                                <p>نام طرح: {{ find('folder_name') }}</p>
                                <p>سرمایه پروژه: {{ find('amount') }}</p>
                                <p>پیش بینی سود: {{ find('interest_rate') }} درصد</p>
                                <p>دوره سرمایه گذاری: {{ find('period') }} ماه</p>
                                <p>نحوه تسویه سود: {{ find('dividend_period') }}</p>
                                <p>نحوه تسویه اصل: {{ 'در سررسید پایان قرارداد' }}</p>
                                <p>مدل تامین مالی: {{ 'شناور' }}</p>
                                <p>حداقل سرمایه گذاری اشخاص حقیقی: {{ '1,000,000 ریال' }}</p>
                                <p>حداکثر سرمایه گذاری اشخاص حقیقی: {{ '5,000,000,000 ریال' }}</p>
                                <!-- <p>حداقل سرمایه گذاری اشخاص حقوقی: {{ find('minimum_amount_per_company') }}</p>
                                <p>حداکثر سرمایه گذاری اشخاص حقوقی: {{ find('maximum_amount_per_company') }}</p> -->
                                <p>وثایق و تضامین: ضمانت نامه بانک ملی <a
                                        href="https://www.bmi.ir/SepamInquery/Inquery/LGInquery" target="_blank">جهت
                                        استعلام ضمانتامه کلیک کنید</a></p>
                                <!-- <p>شناسه طرح نزد سکوی تامین مالی آتیه ایرانیان: {{ find('id') }}</p> -->
                            </div>
                            <div v-if="models === 'person'" class="cc">
                                <p>نام متقاضی: بارمان آژند قرن</p>
                                <p>شناسه ملی: 14009691469</p>
                                <p>شماره ثبت: 570651</p>
                                <p>اطلاعات هیئت مدیره:</p>
                                <p>اطلاعات سهامداران:</p>
                            </div>
                            <div v-if="models === 'k'" class="cc">
                                <div class="calculator flex wrap center">
                                    <div>
                                        <div class="body flex wrap center vcenter">
                                            <div>
                                                <div class="flex wrap center vcenter">
                                                    <input type="text" placeholder="مبلغ سرمایه گذاری"
                                                        v-model="calculator_value" />
                                                    <p>{{ hint['calculator_value'] }}</p>
                                                </div>
                                                <div class="details flex wrap center vcenter">
                                                    <div class="flex wrap center vcenter">
                                                        <p>دوره قرارداد: {{ find('period') }} ماه</p>
                                                    </div>
                                                    <div class="flex wrap center vcenter">
                                                        <p>کل سود پیش بینی شده دوره: {{ find('interest_rate') }} درصد
                                                        </p>
                                                    </div>
                                                    <div class="flex wrap center vcenter">
                                                        <p>دوره تسویه سود: {{ find('dividend_period') }}</p>
                                                    </div>
                                                    <div class="flex wrap center vcenter">
                                                        <p>سود پیش بینی شده هر دوره پروژه: {{ r1 }} ریال</p>
                                                    </div>
                                                    <div class="flex wrap center vcenter">
                                                        <p>ضمانت سرمایه گذاری: ضمانتامه بانکی</p>
                                                    </div>
                                                    <div class="flex wrap center vcenter">
                                                        <p>{{ r2 }} ریال سود پیش بینی شده حاصله از مشارکت</p>
                                                    </div>
                                                </div>
                                                <div class="flex wrap center vcenter"
                                                    @click.prevent="$router.push(`/In/${id}`)">
                                                    <button class="invest">جهت سرمایه گذاری در طرح با مشخصات فوق همین
                                                        الان اقدام
                                                        نمایید.</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="models === 'p'" class="cc">
                                <Form :entity_type="'COMMENT'" :entity="{
                                    title: 'نظرات',
                                    properties: 'R0:key=success,value=YES;R1:key=reload,value=YES',
                                    options: 'R0:key=button,value=cc'
                                }"></Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
/* eslint-disable */
import Header from '../components/Header.vue'
import Progress from '../components/Progress.vue'
import Tabs from '../components/Inputs/Tabs.vue'
import Footer from '../components/Footer.vue'
import Form from '@/components/Form.vue'

export default {
    name: 'Project',
    props: ['id'],
    inject: ['base'],
    components: { Header, Progress, Tabs, Footer, Form },
    created() {
        this.base.API().post('Identities/Folder/Find', { data: { id: Number(this.id) } }
            , (response) => {
                if (this.base.Util().success(response)) {
                    this.values = response.data.data
                    this.p3 = this.p3.replace('x', this.find('folder_name'))
                        .replace('y', this.find('symobl'))
                        .replace('x', this.find('folder_name'))
                        .replace('u', this.find('id'))
                        .replace('k', this.find('company_name'))
                        .replace('m', this.find('folder_name'))
                }
            }, (error) => {

            })
        let parnet_id = ''
        let cc = []
        this.base.API().post('Identities/File/Find', { data: {} }, (response) => {
            if (this.base.Util().success(response)) {
                console.log(response.data.data)
                response.data.data.forEach(element => {
                    if (element.key === 'parent' && element.value === 'تست 2') {
                        parnet_id = element.parnet_id
                    }
                    if (element.key === 'f_amount')
                        this.amount += Number(element.value)

                    if (element.key === 'f_uid') {
                        if (!cc.includes(element.value)) {
                            cc.push(element.value)
                            this.count += 1
                        }
                    }
                })
            }
        })

        this.base.API().post('Data/Meta/Find', { data: {} }, (response) => {
            if (this.base.Util().success(response)) {
                if (this.base.Util().validate(response.data.data))
                    localStorage.setItem('metas', JSON.stringify(response.data.data))
                else
                    localStorage.setItem('metas', JSON.stringify([]))
            }
        })
    },
    data() {
        return {
            count: 0,
            amount: 0,
            models: 'intro',
            values: [],
            p1: `در سکوی تامین مالی جمعی آتیه ایرانیان (آتیا کراد) گزارش پیشرفت و هزینه کرد منابع پروژه به صورت دوره ای، دقیق و شفاف مورد رصد قرار گرفته و در این بخش ، در دسترس سرمایه گذاران قرار خواهد گرفت.
پس از تکمیل وجوه مورد نیاز پروژه و آغاز فرآیند اجرایی پروژه، گزارش نظارت بر پیشرفت پروژه در حین اجرای پروژه و همچنین گزارش حسابرسی پروژه توسط حسابرس معتمد اخذ و بنابر مواعد ذیل در اختیار سرمایه گذاران و فرابورس ایران قرار خواهد گرفت.
•  گزارش اول: پس از اتمام ماه سوم و پس از پرداخت سود پیش بینی شده دوره ای مرحله اول
•  گزارش دوم: پس از اتمام ماه ششم و پس از پرداخت سود پیش بینی شده دوره ای مرحله دوم
•  گزارش سوم: پس از اتمام ماه نهم و پس از پرداخت سود پیش بینی شده دوره ای مرحله نهم
•  گزارش چهارم: پس از اتمام ماه دوازدهم و پس از پرداخت سود پیش بینی شده دوره ای مرحله چهارم
•  گزارش حسابرس: پس از تسویه وجوه قرارداد اعم از سود پیش بینی شده ادوار و اصل وجه سرمایه گذاری`,
            p2: `بجهت جذابیت پروژه از جهت مالی و تضامین پشتوانه پروژه، و همچنین بررسی فرآیندهای مالی و عملیاتی پروژه ، محدودیتی بابت سرمایه گذاری اشخاص وجود نداشته و اشخاص حقیقی و حقوقی میتوانند جهت سرمایه گذاری در طرح فوق اقدام نمایند.
پس از دورۀ جمع آوری وجوه پروژه ، "گواهی شراکت سرمایه گذاری" به عنوان رسمی ترین و معتبر ترین سند شراکت سرمایه گذار در طرح سکوی تامین مالی جمعی آتیه ایرانیان ( آتیا کراد) به شماره مجوز ***** ، از سوی فرابورس ایران صادر و در اختبار سرمایه گذاران قرار خواهد گرفت.
به اطلاع می رساند سند فوق از درگاه سازمان فرابورس به نشانی " پیگیری مشارکت در طرح تامین مالی جمعی" قابل استعلام و اعتبار سنجی می باشد.
جهت سرمایه گذاری در پروژه فقط کافیست ضمن کلیک بر روی دکمه "سرمایه گذاری میکنم" اقدام فرمائید.`,
            p3: `طرح پروژه x با نماد اختصاری y ثبت شده نزد فرابورس ایران به شناسه u طرح شرکت k یک پروژه سرمایه گذاری با موضوع m می باشد که توسط سکوی تامین مالی جمعی آتیه ایرانیان | آتیا کراد به شماره مجوز *** مورد بررسی و جهت تامین مالی در این پلتفرم در اختیار سرمایه گذاران قرار می گیرد:

در راستای شفافیت ، قرارداد و توافقات میان طرفین خدمات تامین مالی جمعی در ادامه تقدیم شده است که ضمن کلیک بر روی هر کدام امکان مطالعه برای متقاضی سرمایه گذاری فراهم می باشد:

مطالعه قرارداد تامین مالی پروژه با نماد
مطالعه پیوست شماره 1: کاربرگ معرفی طرح پروژه و زمانبندی های مرتبط به طرح پروژه
مطالعه پیوست شماره 2: پیش فاکتور ارائه خدمات با موضوع حق الزحمه عامل بابت ارائه خدمات تامین مالی 
مطالعه پیوست شماره 3: تعهدنامه تضمین تعهدات متقاضی توسط ضامن
مطالعه پیوست شماره 4: تعهدنامه رﻋﺎﯾﺖ قوانین مربوط به ﻣﺒﺎرزه ﺑﺎ پولشویی
مطالعه پیوست شماره 5: صورتجلسه تائید گواهی امضاء امضاء کنندگان ذیل اوراق موضوع قرارداد
مطالعه پیوست شماره 6: صورتجلسه اخذ وثایق و تضامین موضوع قرارداد
مطالعه پیوست شماره 7: تائیدیه حسابرس مبنی بر حسابرسی طرح پروژه موضوع قرارداد
مطالعه پیوست شماره 8: تائیدیه عدم سوء پیشینه کیفری متقاضی و اعضای هیئت مدیره
مطالعه پیوست شماره 9: گزارش ارزیابی مالی و فنی متقاضی و طرح پروژه موضوع قرارداد
مطالعه پیوست شماره 10: تفاهم نامه سرمایه گذاری در طرح پروژه موضوع قرارداد
مطالعه پیوست شماره 11: بیانیه پذیرش ریسک سرمایه گذاری
مطالعه پیوست شماره 12: تائیدیه نهاد مالی مبنی بر بررسی و اجازه انتشار پروژه
مطالعه پیوست شماره 13: چک لیست انتشار عمومی طرح پروژه
مطالعه پیوست شماره 14: صورتجلسه اخذ ضمائم الکترونیکی موضوع قرارداد
مطالعه پیوست شماره 15: بررسی و اعلام نتیجه کمیته اعتباری پیرو طرح پروژه
مطالعه پیوست شماره 16: چک لیست امکان سنجی تامین مالی بابت طرح پروژه موضوع قرارداد`,
            calculator_base: '',
            hint: {},
            calculator_value: '',
            r1: '',
            r2: ''
        }
    },
    watch: {
        calculator_value(o, n) {
            this.calculator_value = this.calculator_value.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            this.hint['calculator_value'] = this.calculator_value.replace(/,/g, '').num2persian() + ' ریال'

            this.r1 = String(Math.round(Number(this.calculator_value.replaceAll(',', '')) * Number(this.find('interest_rate')) / 100 / 4)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            this.r2 = String(Math.round(Number(this.calculator_value.replaceAll(',', '')) * Number(this.find('interest_rate')) / 100)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
    },
    methods: {
        find(key) {
            return this.values.find((value) => value.key === key)?.value || ''
        },
        onchange(obj) {
            this.models = obj.value
        },
        like() {
            this.base.API().post('F/Favorite/Register', {
                data: [
                    {
                        uid: Number(this.$cookies.get('uid')),
                        fid: Number(this.id),
                        symbol: this.find('symobl'),
                        name: this.find('folder_name'),
                        amount: this.find('amount'),
                        profit: this.find('interest_rate'),
                        period: this.find('period'),
                        pr: this.find('dividend_period'),
                        own: this.find('repayment_model')
                    }
                ]
            }, (response) => {
                if (this.base.Util().success(response)) {
                    if (this.base.Util().validate(response.data.data))
                        this.base.Notification().toast('نتیجه', 'عملیات با موفقیت انجام شد!', 'success')
                    else
                        this.base.Notification().toast('نتیجه', 'عملیات با خطا مواجه شد!', 'error')
                }
            })
        }
    }
}
</script>

<style scoped src="../assets/css/project.css"></style>