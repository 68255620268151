<template>
    <div class="box flex wrap center">
        <div class="symbol">
            <p>نماد طرح: {{ symbols }}</p>
        </div>
        <div class="banner">
            <img src="/images/test.png" />
        </div>
        <div class="title flex wrap center vcenter">
            <h4>{{ title }}</h4>
        </div>
        <div class="subtitle flex wrap end vcenter">
            <div>
                <h6>{{ c_name }}</h6>
            </div>
        </div>
        <div class="properties flex wrap center">
            <div>
                <p>نرخ سود پیش بینی شده {{ profit }} درصد</p>
            </div>
            <div>
                <p>دوره سرمایه گذاری {{ period }} ماهه</p>
            </div>
            <div>
                <p>تقسیم سود به صورت {{ withdrawal }}</p>
            </div>
            <div>
                <p>تضمین اصل سرمایه توسط ضمانت نامه بانکی</p>
            </div>
        </div>
        <!-- <Progress></Progress> -->
        <div class="action flex wrap center vcenter">
            <a :href="`/Project/${id}`" target="_blank">
                <button>مشاهده جزئیات و سرمایه گذاری </button>
            </a>
        </div>
    </div>
</template>

<script>
import Progress from './Progress.vue'
/* eslint-disable */
export default {
    name: 'CardView',
    props: ['id', 'title', 'profit', 'period', 'withdrawal', 'c_name', 'symbols'],
    components: { Progress }
}
</script>

<style src="../assets/css/cardview.css"></style>