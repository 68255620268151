<template>
    <div class="content">
        <div class="header flex wrap center">
            <Header></Header>
        </div>
        <div class="body flex wrap center">
            <div class="row">
                <div class="banner">
                    <img src="/images/projectbanner.webp" :alt="entity?.folder_name" />
                    <img class="mobile" src="/images/projectoverviewbanner.webp" :alt="entity?.folder_name" />
                </div>
                <section class="categories">
                    <div class="head">
                        <div class="title">
                            <h1>دسته بندی مطالب</h1>
                        </div>
                    </div>
                    <div class="items flex wrap center">
                        <template v-for="(category, index) in categories" :key="index">
                            <a :href="`#${category.key}`">
                                <div class="flex wrap center vcenter">
                                    <img :src="category.image" loading="lazy">
                                    <h4>{{ category.title }}</h4>
                                </div>
                            </a>
                        </template>
                    </div>
                </section>
                <template v-for="(knowledge, index) in knowledges" :key="index">
                    <section v-if="base.Util().validate(entities[knowledge.key]?.entities)" :id="knowledge.key">
                        <div class="head">
                            <div class="title">
                                <h1>{{ knowledge.title }}</h1>
                            </div>
                            <div class="posts flex wrap center vcenter">
                                <div v-if="base.Util().validate(entities[knowledge.key]?.entities)"
                                    class="flex wrap start vcenter">
                                    <template v-for="(entity, index) in entities[knowledge.key]?.entities" :key="index">
                                        <Post :entity="entity" :data="entities[knowledge.key]?.data[entity.id]"></Post>
                                    </template>
                                </div>
                                <div v-else class="flex wrap center vcenter">
                                    <h3>داده‌ای یافت نشد!</h3>
                                </div>
                            </div>
                            <div v-if="base.Util().validate(entities[knowledge.key]?.entities)
                                && entities[knowledge.key]?.entities.length === 4"
                                class="footer flex wrap center vcenter">
                                <a class="flex wrap center vcenter" :href="knowledge.href" target="_blank">
                                    <button>نمایش بیشتر</button>
                                </a>
                            </div>
                        </div>
                    </section>
                </template>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
/* eslint-disable */
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Post from '../components/Post.vue'

import categories from '../assets/data/categories.json'
import knowledges from '../assets/data/knowledges.json'
import base from '@/base/base'

export default {
    name: 'Knowledge',
    inject: ['base'],
    components: { Header, Post, Footer },
    created() {
        // this.$emit('onloading', true)
        // this.base.API().post(`Advertisement/Post/GetLatestPost`, {
        //     data: ['knowledge', 'investment', 'books', 'communicate', 'session', 'live']
        // }, (response) => {
        //     if (this.base.Util().success(response) && response.data.entities) {
        //         this.entities = response.data.entities
        //         this.entities['latest'] = response.data.data
        //     }
        //     this.$emit('onloading', false)
        // }, (error) => {
        //     this.$emit('onloading', false)
        // })
    },
    data() {
        return {
            categories: categories,
            knowledges: knowledges,
            entities: {
                latest: {
                    entities: [{
                        id: '1'
                        , extra_description: 'جمع‌آوری سرمایه یا کرادفاندینگ یک مدل تأمین مالی است که در آن افراد، معمولاً از طریق پلتفرم‌های آنلاین، به صورت مشترک مقادیر کوچکی از پول را برای حمایت از یک پروژه، وانگاری، یا علتی مشارکت می‌کنند. این مدل به عنوان یک وسیله جایگزین برای تأمین سرمایه برای اقدامات مختلف از جمله شرکت‌های نوپا، پروژه‌های خلاقانه، اقدامات خیریه، و موارد دیگر، محبوبیت یافته است. '
                        , title: 'جمع‌آوری سرمایه یا کرادفاندینگ'
                        , category: 'مرکز آموزش آتیاکراد'
                    },
                    {
                        id: '2'
                        , extra_description: `سلام وقتتون بخیر تو این پست میخوایم بپردازیم به تفاوت های کراد فانیدنگ با بقیه روش های تامین مالی:

تامین مالی جمعی و تامین مالی مبتنی بر جذب فروختن سهام (یا به عبارت دقیق‌تر، اقدامات به انتشار عام سهام) دو روش متفاوت برای تامین سرمایه برای یک کسب و کار یا پروژه هستند.`
                        , title: 'کرادفاندینگ یا تامین مالی'
                        , category: 'دانشنامه آتیا'
                    }], data: {}
                },
                knowledge: {
                    entities: [{
                        id: '2'
                        , extra_description: `سلام وقتتون بخیر تو این پست میخوایم بپردازیم به تفاوت های کراد فانیدنگ با بقیه روش های تامین مالی:

تامین مالی جمعی و تامین مالی مبتنی بر جذب فروختن سهام (یا به عبارت دقیق‌تر، اقدامات به انتشار عام سهام) دو روش متفاوت برای تامین سرمایه برای یک کسب و کار یا پروژه هستند.`
                        , title: 'کرادفاندینگ یا تامین مالی'
                        , category: 'دانشنامه آتیا'
                    }], data: {}
                },
                investment: {
                    entities: [{
                        id: '1'
                        , extra_description: 'جمع‌آوری سرمایه یا کرادفاندینگ یک مدل تأمین مالی است که در آن افراد، معمولاً از طریق پلتفرم‌های آنلاین، به صورت مشترک مقادیر کوچکی از پول را برای حمایت از یک پروژه، وانگاری، یا علتی مشارکت می‌کنند. این مدل به عنوان یک وسیله جایگزین برای تأمین سرمایه برای اقدامات مختلف از جمله شرکت‌های نوپا، پروژه‌های خلاقانه، اقدامات خیریه، و موارد دیگر، محبوبیت یافته است. '
                        , title: 'جمع‌آوری سرمایه یا کرادفاندینگ'
                        , category: 'مرکز آموزش آتیاکراد'
                    }], data: {}
                },
                books: { entities: [], data: {} },
                communicate: { entities: [], data: {} },
                session: { entities: [], data: {} },
                live: { entities: [], data: {} }
            }
        }
    }
}
</script>

<style scoped src="../assets/css/views/knowledge.css"></style>