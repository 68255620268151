<template>
    <div class="content">
        <div class="header flex wrap center">
            <Header></Header>
        </div>
        <div class="body flex wrap center">
            <div class="row">
                <Tabs :meta="{
                    type: 'step',
                    extras: 'R0:key=profit,value=مزایای سرمایه گذاری در آتیه ایرانیان;R1:key=process,value=فرآیند سرمایه گذاری در آتیه ایرانیان;R2:key=register,value=ثبت نام و شروع سرمایه گذاری;R3:key=faq,value=سوالات متداول درباره سرمایه گذاری'
                }" :vm="models" @onchange="onchange"></Tabs>
                <div class="container">
                    <div v-if="models === 'profit'">
                        <div class="introduction flex wrap center vcenter">
                            <div class="row">
                                <div class="head flex wrap center vcenter">
                                </div>
                                <div class="body flex wrap center vcenter">
                                    <template v-for="(item, index) in introductions" :key="index">
                                        <div class="flex wrap center vcenter">
                                            <div class=" flex wrap center vcenter"><img class="symbol"
                                                    :src="item['image']" alt="" />
                                            </div>
                                            <h4 class="title">{{ item['title'] }}</h4>
                                            <span class="description">{{ item['description'] }}</span>
                                        </div>
                                    </template>
                                </div>
                                <div class="footer flex wrap center vcenter">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="models === 'process'" id="services" class="services flex wrap center vcenter">
                        <div class="row">
                            <div class="head flex wrap center">
                            </div>
                            <div class="body flex wrap center vcenter">
                                <div class="right flex wrap center vcenter">
                                    <video preload="none" poster="/videos/poster.png" controls>
                                        <source src="/videos/intro.webm" type="video/webm; codecs=vp8.0">
                                    </video>
                                </div>
                                <div class="left flex wrap center vcenter">
                                    <div class="right flex wrap center vcenter">
                                        <div class="step flex vend">
                                            <div class="symbol flex wrap center vcenter">
                                                <img src="images/icons/ss_1.png" />
                                            </div>
                                            <div class="text">
                                                <h4>ثبت نام و تکمیل نمایه</h4>
                                            </div>
                                            <h4 class="stage">1</h4>
                                        </div>
                                        <div class="step flex vend">
                                            <div class="symbol flex wrap center vcenter">
                                                <img src="images/icons/ss_2.png" />
                                            </div>
                                            <div class="text">
                                                <h4>انتخاب طرح و سرمایه گذاری</h4>
                                            </div>
                                            <h4 class="stage">2</h4>
                                        </div>
                                        <div class="step flex vend">
                                            <div class="symbol flex wrap center vcenter">
                                                <img src="images/icons/ss_5.png" />
                                            </div>
                                            <div class="text">
                                                <h4>دریافت گواهی شراکت</h4>
                                            </div>
                                            <h4 class="stage">3</h4>
                                        </div>
                                    </div>
                                    <div class="left flex wrap center vcenter">
                                        <h4>تبریک! به خانواده <a href="https://atiyacrowd.ir">آتیا</a> پیوستید...</h4>
                                        <img src="../assets/images/icon.png" />
                                        <img class="confetti" src="/images/confetti.gif" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="footer flex wrap center vcenter">
                        </div>
                    </div>
                    <div v-else-if="models === 'register'" class="register">
                        <p>همراه گرامی، گروه سرمایه گذاری آتیه ایرانیان یا آماده خدمت گذاری در
                            راستای
                            سرمایه گذاری‌های
                            هدفمند و نیز سودمند است. لذا جهت پیوستن به خانواده پر جمعیت آتیه ایرانیان با کلیک به دکمه
                            زیر به
                            صفحه ثبت نام و ورود
                            به پرتال کاربری خود اقدام فرمایید.</p>
                        <Button
                            :meta="{ key: 'redirect', name: 'پیوستن به خانواده آتیا', style: JSON.stringify({ 'width': '30%' }) }"
                            @onclick="$router.push('/Authentication')"></Button>
                    </div>
                    <div v-else-if="models === 'faq'" class="faq">
                        <p>جهت سهولت در فرآیند کار با سکوی تأمین مالی جمعی آتیه ایرانیان ، تعدادی
                            از
                            سوالات متداول شما در
                            بخش ذیل گردآوری شده؛ در صورت وجود هرگونه ابهام در رابطه با مشارکت و سرمایه‌گذاری لطفا به
                            پرتال کاربری مراجعه و
                            نسبت به ثبت تیکت پشتیبانی اقدام فرمایید.</p>
                        <Accordion :title="'آتیه ایرانیان چیست؟'"
                            :description="' تامین مالی جمعی آتیه ایرانیان ، سکو تامین مالی جمعی دارای مجوز از فرابورس ایران به شماره مجوز و ثبت شده نزد اداره ثبت شرکتها و موسسات غیرتجاری استان تهران به شماره ثبتی 570651 می باشد که با اتکا بر دانش و تجربه مدیران، مشاوران و متخصصان حوزه کسب و کار و بازار پول و سرمایه به شناسایی طرح ها و پروژه های مولد و سودزا و تامین سرمایه مورد نیاز آنها می نماید، شایان ذکر است این مجموعه با استفاده از ظرفیت های موجود در کشور ضمن توجه ویژه به حوزه دانش بنیان و کسب و کار های نوپا در تلاش است بستری امن را برای سرمایه گذاران و سرمایه پذیران فعالان حوزه اقتصادی فراهم نماید. '">
                        </Accordion>
                        <!-- <Accordion :title="'روش کار آتیه ایرانیان چگونه است؟'"></Accordion>
                        <Accordion :title="'ضمانت سرمایه من چگونه است؟'"></Accordion>
                        <Accordion :title="'آیا می‌توانم سرمایه خودم را پیش از موعد مقرر برداشت کنم؟'"></Accordion>
                        <Accordion :title="'نحوه پرداخت سود حاصله از مشارکت چگونه است؟'"></Accordion>
                        <Accordion :title="'چگونه می‌توانم سرمایه‌گذاری کنم؟'"></Accordion> -->
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
/* eslint-disable */
import Header from '../components/Header.vue'
import Tabs from '../components/Inputs/Tabs.vue'
import Accordion from '../components/Accordion.vue'
import Button from '../components/Inputs/Button.vue'
import Footer from '../components/Footer.vue'

export default {
    name: 'Investment',
    components: { Header, Tabs, Accordion, Button, Footer },
    data() {
        return {
            models: 'profit',
            introductions: [
                {
                    title: 'مطابق با عقود اسلامی',
                    description: 'مورد استفتاء از دفاتر مراجع عظام تقلید و تحت نظر شورای فقه اقتصادی',
                    image: '/images/icons/i_2.png'
                },
                {
                    title: 'قانونی و دارای مجوز',
                    description: 'تحت نظارت و دارای مجوز از فرابورس ایران',
                    image: '/images/icons/i_13.png'
                },
                {
                    title: 'قابل دسترس',
                    description: 'همیشه و همه جا به پرونده های خود دسترسی دارید',
                    image: '/images/icons/i_8.png'
                },
                {
                    title: 'اطلاع رسانی سریع',
                    description: 'با بیش از ۱۰ پل ارتباطی همواره در جزئیات اخبار و اطلاعیه ها خواهید بود',
                    image: '/images/icons/i_5.png'
                },
                {
                    title: 'مسلط و دارای سابقه درخشان',
                    description: 'دارای ۱۵ سال سابقه کار مالی و تامین مالی در حوزه های مختلف',
                    image: '/images/icons/i_3.png'
                }
            ],
        }
    },
    methods: {
        onchange(obj) {
            this.models = obj.value
        }
    }
}
</script>

<style scoped src="../assets/css/investment.css"></style>