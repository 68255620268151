<template>
    <div class="post">
        <a :href="`/Post/${entity?.id}`" target="_blank" :style="{ minHeight: minHeight }">
            <div class="thumbnail">
                <img src="/images/projectoverviewbanner.webp">
            </div>
            <div class="data">
                <div class="full flex wrap center vcenter">
                    <h1>{{ entity?.title }}</h1>
                </div>
                <p>{{ entity?.extra_description }}</p>
                <div v-if="entity?.category" class="category flex wrap start vcenter">
                    <span>{{ entity?.category }}</span>
                </div>
                <!-- <div class="metas flex wrap center vcenter">
                    <div class="flex wrap space vcenter">
                        <div class="flex center">
                            <i class="fe fe-16 fe-calendar"></i>
                            <span>{{ data?.publish_at || '' }}</span>
                        </div>
                        <div class="flex center">
                            <i class="fe fe-16 fe-message-circle"></i>
                            <span>{{ data?.comments || 0 }}</span>
                        </div>
                        <div class="flex center">
                            <i class="fe fe-16 fe-eye"></i>
                            <span>{{ data?.views || 0 }}</span>
                        </div>
                        <div class="flex center">
                            <i class="fe fe-16 fe-star"></i>
                            <span>{{ data?.rates || 0 }}</span>
                        </div>
                    </div>
                </div> -->
            </div>
        </a>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'Post',
    props: ['minHeight', 'href', 'entity', 'data']
}
</script>

<style scoped src="../assets/css/components/post.css"></style>